import React from "react";
import ReactExport from "react-export-excel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export class CartExport extends React.Component {

    render() {
        let dataSet1 = this.props.items;
        let dates = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"];
        let month = ["Января","Февраля","Марта","Апреля","Мая","Июня","Июля","Августа","Сентября","Октября","Ноября","Декабря"];
        let monthNum = ["01","02","03","04","05","06","07","08","09","10","11","12"];
        return (
            <ExcelFile filename={"selected_Mars_products_" + dates[new Date().getDate()] + "_" + monthNum[new Date().getMonth()] + "_" + new Date().getFullYear()} element={<Button disabled={!this.props.items || this.props.items.length < 1}><FontAwesomeIcon icon={faFileExcel}/> Экспорт</Button>}>
                <ExcelSheet data={dataSet1} name="Избранные товары MARS" >
                    <ExcelColumn label="Наименование товара" value="name"/>
                    <ExcelColumn label="Маркетинговое наименование товара" value={(col) => col.optionalAttrs && col.optionalAttrs.find(a=> a.id === "53052943")? col.optionalAttrs.find(a=> a.id === "53052943").value: "-"  }/>
                    <ExcelColumn label="Gtin" value="gtin"/>
                    <ExcelColumn label="Внутреннее наименование" value={(col) => col.optionalAttrs && col.optionalAttrs.find(a=> a.id === "53049077")? col.optionalAttrs.find(a=> a.id === "53049077").value: "-"  }/>
                    <ExcelColumn label="Внутренний артикул" value={(col) => col.optionalAttrs && col.optionalAttrs.find(a=> a.id === "53049085")? col.optionalAttrs.find(a=> a.id === "53049085").value: "-"  }/>
                    <ExcelColumn label="Ссылка на товар"
                                 value={(col) => "https://mars.animotech.ru/details/" + col.id}/>
                    <ExcelColumn label="Дата последнего обновления"
                                 value={(col) =>
                                     col.date? dates[new Date(col.date).getDate()] + " " + month[new Date(col.date).getMonth()] + " " + new Date(col.date).getFullYear():""
                                 }/>
                    <ExcelColumn label="Ссылка на главное изображение"
                                 value={(col) =>
                                     col.image200x200.replace('&wmax=200&hmax=200','')? col.image200x200:""
                                 }/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
