import React, { Component } from "react";
import { connect } from "react-redux";
import { showCartDlg} from "../../Redux/Actions";
import { withRouter } from "react-router-dom";
import CartRow from "./CartRow";
import {CartExport} from "./CartExport"
import {Modal} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
const mapStateToProps = state => {
  return { open: state.showCartDialog, items: state.cartItems };
};

class ConnectedCartDialog extends Component {
  render() {
    return (

        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.props.open}
            onHide={() => {
                this.props.dispatch(showCartDlg(false));
            }}>
          <Modal.Header className="justify-content-start" style={{backgroundColor:"#f8f9fa"}}>
              <FontAwesomeIcon className="mr-2" style={{color:"#0118a0"}} icon={faHeart}/> Избранные товары
          </Modal.Header>

          <Modal.Body>
              {this.props.items && this.props.items.length > 0 &&
                <table className="favorites-list">
                    <thead>
                        <tr>
                            <th style={{width:80}}/>
                            <th/>
                            <th style={{width:50}}/>
                        </tr>
                    </thead>
                    <tbody>
                          {this.props.items.map((item, index) => {
                              return <CartRow item={item} key={item.id} {...this.props} />;
                          })}
                    </tbody>
              </table>
              }
              {(!this.props.items || this.props.items.length < 1) &&
                <div className="py-5 text-center font-italic">
                    Нет избранных товаров
                </div>
              }
          </Modal.Body>

          <Modal.Footer>
            <CartExport items={this.props.items}/>
          </Modal.Footer>
        </Modal>

    );
  }
}
const CartDialog = withRouter(connect(mapStateToProps)(ConnectedCartDialog));
export default CartDialog;
