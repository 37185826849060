import React from "react";

import {
  showCartDlg,
  deleteCartItem,
} from "../../Redux/Actions";

import {Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CartRow = props => {
  let { item } = props;
  return (
    <tr>
        <td>
            <div className="favorites-image" style={{backgroundImage: "url(" + item.image200x200.replace('http://mdm.esphere.ru','').replace('http://demo-mdm.esphere.ru','') + ")"}} />
        </td>
        <td>
            <Link to={`/details/${item.id}`}>
                <div onClick={() => {props.dispatch(showCartDlg(false));}}>
                    {item.name}
                </div>
            </Link>
            <p className="mt-1">{item.gtin}</p>
        </td>

        <td>
            <Button variant="danger" onClick={() => {props.dispatch(deleteCartItem(item.id));}}>
                <FontAwesomeIcon icon={faTrash}/>
            </Button>
        </td>
    </tr>
  );
};

export default CartRow;
