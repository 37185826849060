import {brands} from "./Data";
import {Component} from "react";

class Api extends Component {
    getItemUsingID = async (id) => {
        return fetch('https://showcase.animotech.io/product?id=' + id, {
            method: 'GET'
        })
            .then(r => {
                if (r.status !== 401) return r.json();
                else return false
            })
            .then(rJson => {
                if (rJson) {
                    let res = rJson.data;
                    return res.length === 0 ? null : res
                }
                else return false
            })
    };
    searchItems = async ({ category = "", term = "", page = 1 }) => {
        return new Promise((resolve, reject) => {
            let searchParams = new URLSearchParams();
            searchParams.append("page", page);
            console.log('1');
            let categoryId = brands.find(item => item.id === category);
            if (category && category.length > 0) {
                searchParams.append("brand", categoryId.value_ru);
            }
            if (term && term.length > 0) {
                searchParams.append("search", term);
            }
            fetch('https://showcase.animotech.io/products?' + searchParams.toString(), {
                method: 'GET'
            })
                .then(r => r.json())
                .then(rJson => {
                    console.log(rJson.data);
                    let data = rJson.data;
                    let totalLength = rJson.total_rows;
                    console.log(data);
                    return resolve({data, totalLength});
                }).catch(err => {})
        });
    };
}

export default new Api();
