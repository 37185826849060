import React, {Component} from "react";
import Item from "../Item/Item";
import queryString from "query-string";
import Api from "../../Api";
import Paging from "../Paging/Paging";
import {Container, Row, Spinner, Col} from "react-bootstrap"
import ProductsHeader from "../ProductsHeader/ProductsHeader";

import MetaTags from "react-meta-tags";


class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            totalItemsCount: null,
            items: [],
            view: 1
        };
        this.updateQueryStr = this.updateQueryStr.bind(this);
        this.toggleView = this.toggleView.bind(this)
    }

    toggleView = (view) => {
        this.setState({
            view: view
        })
    };

    async fetchData() {
        this.setState({loading: true});
        let qsAsObject = queryString.parse(this.props.location.search);
        let results = await Api.searchItems(qsAsObject);
        results.data.forEach((item, index) => {
            if (item.files && item.files.length > 0) {
                let ecomImages = item.files.filter(f => f.photo_type === "E-com" && f.type === "Фото");
                if (ecomImages && ecomImages.length > 0) {
                    let mainEcomImage = ecomImages.find(i => i.photo_angle === "Лицевая сторона");
                    if (mainEcomImage && mainEcomImage.url) {
                        results.data[index].image200x200 = mainEcomImage.url + '?wmax=500&hmax=500'
                    }
                }
            }
        });

        this.setState({
            items: results.data,
            loading: false,
            totalItemsCount: results.totalLength
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    updateQueryStr(newValues) {
        let current = queryString.parse(this.props.location.search);
        this.props.history.push(window.location.pathname +
            "?" + queryString.stringify({...current, ...newValues})
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let currentQueryStr = queryString.parse(this.props.location.search);
        let oldQueryStr = queryString.parse(prevProps.location.search);

        let areSameObjects = (a, b) => {
            if (Object.keys(a).length !== Object.keys(b).length) return false;
            for (let key in a) {
                if (a[key] !== b[key]) return false;
            }
            return true;
        };

        // We will refetch products only when query string changes.
        if (!areSameObjects(currentQueryStr, oldQueryStr)) {
            this.fetchData();
        }
    }

    render() {
        let parsedQueryStr = queryString.parse(this.props.location.search);
        let category = parsedQueryStr.category;
        let title = category ? "MARS - товары бренда " + category : "MARS - мировые бренды Petcare, Food, Mars Wrigley"
        if (this.state.loading) {
            return (<div style={{
                width: "100%",
                height: "calc(100vh - 180px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}><Spinner style={{color: "#00d7b9"}} animation="border"/></div>);
        }

        return (

            <div style={{width: "100%"}}>
                <MetaTags>
                    <title>{title}</title>
                    <meta name="description" content=""/>
                </MetaTags>
                <div style={{height: "100%", display: "flex", flexDirection: "column", paddingBottom: 60}}>

                    <Container fluid>
                        <ProductsHeader
                            parsedQueryStr={parsedQueryStr}
                            updateQueryStr={this.updateQueryStr}
                            totalItemsCount={this.state.totalItemsCount}
                            toggleView={this.toggleView}
                            view={this.state.view}
                        />
                        <Row>
                            <Col xs={12} sm={6}>

                            </Col>
                            <Col xs={12} sm={6}>

                            </Col>
                        </Row>
                        <Row>
                            {this.state.items.map(item => {
                                return <Item view={this.state.view} key={item.id} item={item}/>;
                            })}
                        </Row>
                    </Container>
                    <Paging
                        parsedQueryStr={parsedQueryStr}
                        updateQueryStr={this.updateQueryStr}
                        totalItemsCount={this.state.totalItemsCount}
                    />
                </div>
            </div>
        );
    }
}

export default ProductList;
