import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {showCartDlg, addLocalToCart} from "../../Redux/Actions";
import cartImage from "../../Images/logo2.png";
import {faHeart, faBookmark, faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Navbar, Nav} from "react-bootstrap";


const mapStateToProps = state => {
  return {
    nrOfItemsInCard: state.cartItems.length,
    loggedInUser: state.loggedInUser
  };
};

// Option items for product categories.


class ConnectedHeader extends Component {
  state = {
    searchTerm: "",
    anchorEl: null,
    categoryFilterValue: "",
  };

  constructor(props) {
    super(props);
    this.keyPress = this.keyPress.bind(this);
    this.state = {
        showSearch: false
    }
  }
    componentDidMount() {
        let favorites = localStorage.getItem('Favorites');
        let data = JSON.parse(favorites);
        if (data) {
            this.props.dispatch(
                addLocalToCart(data.value)
            );
        }
    }
    navigateTo = (e,link) => {
        e.preventDefault();
        this.props.history.push(link);
    };
  keyPress(e){
    if (this.state.searchTerm.length > 0 && e.keyCode === 13) {
      this.props.history.push(
          "/?category=" +
          this.state.categoryFilterValue +
          "&term=" +
          this.state.searchTerm
      );
    }
  }

  render() {
    return (
      <Navbar
          collapseOnSelect sticky="top" expand="xl" variant="light" bg="light"
      >
          <Navbar.Brand href="/">
              <img src={cartImage} alt={"Logo"} />
          </Navbar.Brand>
          <div style={{display:'flex'}}>
              <Nav.Link onClick={() => {this.props.dispatch(showCartDlg(true));}} className="d-block d-xl-none justify-content-center nav-mobile-icon">
                  <div className="d-flex justify-content-center nav-mobile-icon-area">
                      <FontAwesomeIcon icon={faHeart}/>
                  </div>
                  <span>Избранное</span>
              </Nav.Link>
              <Nav.Link className="d-block d-lg-none justify-content-center nav-mobile-icon" onClick = {() => this.props.toggleSideBar()}>
                  <div className="d-flex justify-content-center nav-mobile-icon-area">
                      <FontAwesomeIcon className="d-block" icon={faBookmark}/>
                  </div>
                  <span>Бренды</span>
              </Nav.Link>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="nav-mobile-icon">
                  <div className="d-flex justify-content-center nav-mobile-icon-area">
                    <FontAwesomeIcon icon={faBars}/>
                  </div>
                  <span>Меню</span>
              </Navbar.Toggle>

          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                  <Nav.Link href="https://rus.mars.com/vse-o-mars">Все о Mars</Nav.Link>
                  <Nav.Link href="/" onClick={(e) => {this.navigateTo(e,'/')}}>Наши бренды</Nav.Link>
                  <Nav.Link href="https://rus.mars.com/ustoychivost-cherez-pokolenie">Устойчивость через поколение</Nav.Link>
              </Nav>
              <Nav.Link  className="d-none d-xl-block" onClick={() => {
                  this.props.dispatch(showCartDlg(true));}}>
                  <FontAwesomeIcon icon={faHeart}/> {this.props.nrOfItemsInCard === 0 ?
                      "Избранное"
                      :
                      this.props.nrOfItemsInCard > 0 && "Товаров: " + this.props.nrOfItemsInCard
                  }
              </Nav.Link>
          </Navbar.Collapse>

      </Navbar>
    );
  }
}

const Header = withRouter(connect(mapStateToProps)(ConnectedHeader));
export default Header;
