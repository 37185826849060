import React, { Component } from "react";
import { dataForTheMenu } from "../../Data";
import {Container, Row, Spinner, Col, Button } from "react-bootstrap"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {faAngleLeft,faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MetaTags from 'react-meta-tags';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 801 },
    items:3
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items:2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const ButtonGroup = ({ next, previous, goToSlide }) => {

  return (
      <div className="carousel-button-group">
        <Button onClick={() => previous()}>
          <FontAwesomeIcon  icon={faAngleLeft}/>
        </Button>
        <Button onClick={() => next()} >
          <FontAwesomeIcon  icon={faAngleRight}/>
        </Button>
      </div>
  );
};
class brandList extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  navigateTo = (e,link) => {
    e.preventDefault();
    this.props.history.push(link);
  };

  render() {

    if (this.state.loading) {
      return <div style={{width:"100%", height:"calc(100vh - 180px)", display: "flex",justifyContent:"center", alignItems:"center"}}><Spinner style={{color:"#00d7b9"}} animation="border" /></div>;
    }

    return (
      <Container className="">
        <MetaTags>
          <title>MARS - Мировые бренды Petcare, Food, Mars Wrigley и Edge</title>
          <meta name="description" content="На протяжении многих поколений ты и твоя семья, включая домашних животных, любят наши бренды, продукты и услуги. Мы это ценим и постоянно совершенствуем их, чтобы радовать тебя новинками!
Делаем мир для домашних животных лучше! Лучшие моменты дарят миру улыбки! Лучшая еда сегодня для лучшего мира завтра!" />
        </MetaTags>
        <Row className="mt-5 ">
          <Container className="brand-list-container">
            <h1>
              Наши бренды
            </h1>
            <Row>
            <Col xs={12} md={6} >
              <p className="">
                На протяжении многих поколений ты и твоя семья, включая домашних животных, любят наши бренды, продукты и услуги. Мы это ценим и постоянно совершенствуем их, чтобы радовать тебя новинками!
              </p>
            </Col>
            <Col xs={12} md={6} className=" text-right">
              <a href="# " style={{fontSize:30}} onClick={(e) => {this.navigateTo(e,'/products')}}>Все товары <span className="font-weight-bold">MARS</span> <FontAwesomeIcon  icon={faAngleRight}/></a>
            </Col></Row>

          </Container>
          {
            dataForTheMenu.map((x, i) => {
              return (
              <Row key={i} className="brandContainer">
                <Col xs={12} lg={{span: 6, order: i === 1 ?'last':'first'}} className="brand-content">
                  <h2>MARS <span>{x.name}</span></h2>
                  <p>{x.desc}</p>
                  <p className="brand-counter">Количество брендов: {x.children.length}</p>
                  <Carousel showDots={false} infinite={true} arrows={false} responsive={responsive} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}>
                    {x.children.map((y, i) => {
                      return (
                          <div  key={i} className="brandLogo" style={{ margin:"10px 10px 10px 0", width:120, height: 120, float:"left", position:"relative"}}>

                              {y.image ?
                                  <a href="# " onClick={(e) => {this.navigateTo(e,y.url)}}><img  alt={y.name} style={{width:"100%"}} src={y.image}/></a>
                                :
                                  <a href="# " className="nologolink" onClick={(e) => {this.navigateTo(e,y.url)}}>{y.name}</a>}
                          </div>
                      )
                    })}
                  </Carousel>
                </Col>
                <Col xs={{span: 12, order: 'first'}} lg={{span: 6, order: i !== 1 ?'last':'first'}}
                     style={{backgroundImage: "url(" + x.img + ")", minHeight: 250, backgroundRepeat:"no-repeat", backgroundPosition:'50%', backgroundSize:"cover"}}
                >

                </Col>
              </Row>)
            })
          }
        </Row>
      </Container>
    );
  }
}

export default brandList;
