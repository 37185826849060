import React from "react";
import { withRouter } from "react-router-dom";
import {
  faChevronRight,
  faChevronLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";


const Paging = props => {
  let { parsedQueryStr } = props;
  let itemsPerPage = parseInt(parsedQueryStr.itemsPerPage) || 24;
  let page = parseInt(parsedQueryStr.page) || 1;
  let totalPages = Math.ceil(props.totalItemsCount / itemsPerPage);

  if (!props.totalItemsCount) return null;

  return (
    <div className="paging">
      <Button  disabled={page <= 1} onClick={() => {props.updateQueryStr({ page: 1 });}}>
        <FontAwesomeIcon  icon={faAngleDoubleLeft}/>
      </Button>
      <Button disabled={page === 1} onClick={() => {props.updateQueryStr({ page: page - 1 });}}>
        <FontAwesomeIcon  icon={faChevronLeft}/>
      </Button>
      <span>
        Страница {page} из {totalPages}
      </span>
      <Button disabled={page >= totalPages} onClick={() => {props.updateQueryStr({ page: page + 1 });}}>
        <FontAwesomeIcon  icon={faChevronRight}/>
      </Button>
      <Button  disabled={page >= totalPages} onClick={() => {props.updateQueryStr({ page: totalPages });}}>
        <FontAwesomeIcon  icon={faAngleDoubleRight}/>
      </Button>
    </div>
  );
};

export default withRouter(Paging);
