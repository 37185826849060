import React, {Component} from "react";
import ReactDOM from "react-dom";
import App from "./App";
import PropTypes from 'prop-types';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import HttpsRedirect from 'react-https-redirect';
import ym, {YMInitializer} from "react-yandex-metrika";
console.disableYellowBox = true;
class LocationListener extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    componentDidMount() {
        this.handleLocationChange(this.context.router.history.location);
        this.unlisten = this.context.router.history.listen(this.handleLocationChange);
    }

    componentWillUnmount() {
        this.unlisten();
    }

    handleLocationChange(location) {
        ym('hit', location.pathname);
    }

    render() {
        return this.props.children;
    }
}

let app = (
  <Provider store={store}>
      <HttpsRedirect>
        <BrowserRouter>
            <LocationListener>
                <YMInitializer accounts={[74235727]} />
                <App />
            </LocationListener>
        </BrowserRouter>
      </HttpsRedirect>
  </Provider>
);

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

ReactDOM.render(app, document.getElementById("root"));
