import React, {Component} from "react";
import {connect} from "react-redux";
import {addItemInCart} from "../../Redux/Actions";
import {withRouter} from "react-router-dom";
import {Card, Button, Col} from "react-bootstrap"
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {faHeart as fasHeart} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const mapState = state => {
    return {
        cartItems: state.cartItems
    };
};


class ConnectedItem extends Component {
    componentDidMount() {
        let url = this.props.item.image200x200.replace('http://mdm.esphere.ru', '').replace('http://demo-mdm.esphere.ru', '').replace('?wmax=200&hmax=200', '?wmax=500&hmax=500')
        console.log(this.props.item.image200x200);
        console.log( + ")");
    }

    render() {
        let {view} = this.props;
        let favorites = this.props.cartItems;
        let optionalName = this.props.item.optionalAttrs.find(attr => attr.id === "53052943");
        return (
            <Col xs={12} sm={view === 1 ? 6 : 12} md={view === 1 ? 4 : 12} lg={view === 1 ? 4 : 12}
                 xl={view === 1 ? 3 : 12} className={view === 1 ? "productContainer" : "productContainer list-view"}>
                <Card className="product">
                    <div className="product-image"
                         style={{backgroundImage: "url('" + this.props.item.image200x200.replace('http://mdm.esphere.ru', '').replace('http://demo-mdm.esphere.ru', '').replace('?wmax=200&hmax=200', '?wmax=500&hmax=500') + "')"}}/>
                    <div className="product-image-cover" onClick={() => {
                        this.props.history.push("/details/" + this.props.item.id);
                    }}/>
                    <Card.Body>
                        <Card.Text>
                            GTIN: {this.props.item.gtin}
                        </Card.Text>
                        <Card.Title>
                            {optionalName ? optionalName.value : this.props.item.name}
                        </Card.Title>

                        <Button onClick={() => {
                            this.props.history.push("/details/" + this.props.item.id);
                        }} className="more" variant="primary">Подробнее</Button>

                    </Card.Body>
                    {
                        favorites.findIndex(i => i.id === this.props.item.id) === -1 ?
                            <FontAwesomeIcon className="addToFavorites" icon={fasHeart} onClick={e => {
                                e.stopPropagation();
                                this.props.dispatch(
                                    addItemInCart({...this.props.item, quantity: 1})
                                );
                            }}/>
                            :
                            <FontAwesomeIcon className="addToFavorites" icon={faHeart} onClick={e => {
                                e.stopPropagation();
                                this.props.dispatch(
                                    addItemInCart({...this.props.item, quantity: 1})
                                );
                            }}/>
                    }
                </Card>
            </Col>
        );
    }
}

export default withRouter(connect(mapState)(ConnectedItem));
