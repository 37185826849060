import React, { Component } from "react";
import { Button, Spinner } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Api from "../../Api";
import { connect } from "react-redux";
import {DetailsExport} from "./DetailsExport"
import {Container, Row, Col, Tabs, Tab, Accordion, Card} from "react-bootstrap"

import {
    faAngleRight,
    faBox,
    faBoxes,
    faTruckLoading,
    faPallet,
    faDownload,
    faAngleLeft, faLayerGroup
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import nophoto from '../../Images/nophoto.png';
import MetaTags from "react-meta-tags";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 800 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 800, min: 700 },
        items:5
    },
    tablet: {
        breakpoint: { max: 700, min: 500 },
        items:4
    },
    mobile: {
        breakpoint: { max: 500, min: 350 },
        items: 3
    },
    mobileSmall: {
        breakpoint: { max: 350, min: 0 },
        items: 2
    }
};
const contry = [
    {
        n: '643',
        v: 'Россия'
    },
    {
        n: '528',
        v: 'Нидерланды'
    },
    {
        n: '616',
        v: 'Польша'
    },
    {
        n: '276',
        v: 'Германия'
    },
    {
        n: '348',
        v: 'Венгрия'
    },
    {
        n: '250',
        v: 'Франция'
    },
    {
        n: '840',
        v: 'США'
    },
    {
        n: '036',
        v: 'Австралия'
    },
    {
        n: '826',
        v: 'Соед. Королев.'
    },
    {
        n: '756',
        v: 'Швейцария'
    },
    {
        n: '764',
        v: 'Тайланд'
    },
    {
        n: '040',
        v: 'Австрия'
    },
    {
        n: '056',
        v: 'Бельгия'
    },
    {
        n: '380',
        v: 'Италия'
    },
    {
        n: '203',
        v: 'Чехия'
    }
];

class ConnectedDetails extends Component {
  constructor(props) {
    super(props);

    this.isCompMounted = false;

    this.state = {
      relatedItems: [],
      quantity: 1,
      item: null,
      itemLoading: false
    };
  }

  async fetchProductAndRelatedItems(productId) {
    this.setState({ itemLoading: true });

    let item = await Api.getItemUsingID(productId);


    //let relatedItems = await Api.searchItems({
    //  category: item.category
    //});

    // Make sure this component is still mounted before we set state..
    if (this.isCompMounted && item) {
        let images = item.files.filter(i => i.type === "Фото" && i.mime !== "image/psd" && i.mime !== "image/tiff" && i.mime !== 'application/pdf');
        let ecom = images.filter(i => i.photo_type && i.photo_type.indexOf("E-com") !== -1 );
        if (ecom && ecom.length > 0) {
            images = ecom;
        }
        let docs = item.files.filter(i => i.type === "Документ");
        let mainImage;
        if (images.length > 0 ) {
            mainImage = images.find(i => i.photo_is_main === 1);
            if (!mainImage) {
                mainImage = images.find(i => i.photo_angle === "Лицевая сторона");
            }
            if (!mainImage) {
                mainImage = images[0];
                images[0].photo_is_main = 1;
            }
        }
        else {
            mainImage = false
        }
        images.sort(( a, b ) =>  a.sort_order - a.sort_order);
        item.attrs = item.attrs.filter(a => a.group_name !== "Параметры ДИКСИ");
        item.attrs = item.attrs.filter(a => a.id !== "3477097796");
        item.attrs = item.attrs.filter(a => a.id !== "3477096382");
        item.attrs = item.attrs.filter(a => a.id !== "3477095978");
        item.attrs = item.attrs.filter(a => a.id !== "3477521361");
        item.attrs = item.attrs.filter(a => a.id !== "3477096988");
        item.attrs = item.attrs.filter(a => a.id !== "3477093405");

        item.attrs = item.attrs.filter(a => a.id !== "3477095776");
        item.attrs = item.attrs.filter(a => a.id !== "3477093809");
        item.attrs = item.attrs.filter(a => a.id !== "3477097190");
        item.attrs = item.attrs.filter(a => a.id !== "3477096180");
        item.attrs = item.attrs.filter(a => a.id !== "3477097392");
        item.attrs = item.attrs.filter(a => a.id !== "3477096584");

        item.attrs = item.attrs.filter(a => a.id !== "3477562121");
        item.attrs = item.attrs.filter(a => a.id !== "3477096786");
        item.attrs = item.attrs.filter(a => a.id !== "3477098200");
        item.attrs = item.attrs.filter(a => a.id !== "3477093203");
        item.attrs = item.attrs.filter(a => a.id !== "3477093607");
        item.attrs = item.attrs.filter(a => a.id !== "3477098973");

        item.attrs = item.attrs.filter(a => a.id !== "3477097998");
        item.attrs = item.attrs.filter(a => a.id !== "3477098674");
        item.attrs = item.attrs.filter(a => a.id !== "3477480064");
        item.attrs = item.attrs.filter(a => a.id !== "3477098472");
        item.attrs = item.attrs.filter(a => a.id !== "3726426958");

        item.attrs.sort(function(a,b){
            return a.order - b.order
        });

        let userAttr = item.attrs.filter(a => a.kind === "4");
        let classifier = item.attrs.filter(a => a.kind === "1");
        let profile = item.attrs.filter(a => a.kind === "3");
        let logistic = item.attrs.filter(a => a.kind === "2");
        let merchandising  = item.attrs.filter(a =>  a.kind === "5");
        let marketing = item.attrs.filter(a =>  a.kind === "6");
        let channels  = item.attrs.filter(a =>  a.kind === "7" && a.for_company_id === "1944");
        let mars  = item.attrs.filter(a =>  a.kind === "8");
        let otherAttrs  = item.attrs.filter(a =>  a.kind === "ext");

        let cont = classifier.find(a => a.id == "53049113");
        if (cont) {
            if (contry.findIndex(c => c.n === cont.value) !== -1) {
                let index = classifier.findIndex(a => a.id == 53049113);
                classifier[index].value = contry.find(c => c.n === cont.value).v;
            }
        }

        logistic.sort(function(a,b){
            return a.order - b.order
        })
        merchandising.sort(function(a,b){
            return a.order - b.order
        })
        profile.sort(function(a,b){
            return a.order - b.order
        })
        userAttr.sort(function(a,b){
            return a.order - b.order
        })
        let size = item.packages.find(p => p.type === "Единичная");
        let sizes = item.packages
        let desc = item.attrs.find(a => a.id === "53049095");
        if (desc) {
            desc = desc.value;
        }
        else {
            desc = false
        }


      this.setState({
        item,
        quantity: 1,
        //relatedItems: relatedItems.data.filter(x => x.id !== item.id),
        itemLoading: false,
          mainImage: mainImage,
          documents: docs,
          images: images,

          classifier: classifier,
          logistic: logistic,
          profile: profile,
          userAttr: userAttr,
          merchandising: merchandising,
          marketing: marketing,
          channels: channels,
          mars:mars,
          otherAttrs: otherAttrs,

          size: size,
          sizes: sizes,
          activeTab: 0,
          description: desc
      });
    }

    if (!item) {
        Api.getNewSession().then(() => {
            this.fetchProductAndRelatedItems(productId)
        })
    }
  }
  downloadImage = (image) => {
        fetch(image.url, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", image.display_name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };
  downloadAllImages = () => {
      this.state.images.forEach((i) => {
          this.downloadImage(i);
      })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // If ID of product changed in URL, refetch details for that product
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchProductAndRelatedItems(this.props.match.params.id);
    }
  }

  componentDidMount() {
    this.isCompMounted = true;
    this.fetchProductAndRelatedItems(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.isCompMounted = false;
  }

  render() {
    if (this.state.itemLoading) {
      return (
          <div style={{width:"100%", height:"calc(100vh - 180px)", display: "flex",justifyContent:"center", alignItems:"center"}}><Spinner style={{color:"#00d7b9"}} animation="border" /></div>
      );
    }

    if (!this.state.item) {
      return null;
    }
    let dates = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"]
    let month = ["Января","Февраля","Марта","Апреля","Мая","Июня","Июля","Августа","Сентября","Октября","Ноября","Декабря"]
    let optionalName = this.state.item.attrs.find(attr => attr.id === "53052943");

    let title = optionalName? optionalName.value : this.state.item.prod_name;
    let desc = this.state.description? this.state.description : "";
    let tags = this.state.marketing.find(i => i.id === "53052939"); //53052943
    let tagText = tags? tags.value : "";
;
    return (
      <Container className="product-details-container" fluid>
          <MetaTags>
              <title>{title}</title>
              <meta name="description" content={desc} />
              <meta name="keywords" content={tagText} />
          </MetaTags>
          <Row>
              <Col xs={12} style={{padding: "10px 40px 0"}}>
                  <a href="# " className="back" onClick={() => this.props.history.goBack()}><FontAwesomeIcon icon={faAngleLeft}/> Вернуться назад</a>
              </Col>
          </Row>
          <Row>

              <Col xs={{ span: 12, order: 'last' }} lg={{ span: 7, order: 'first' }} className="product-details">
                      <h1 className="mt-4">
                          {optionalName? optionalName.value : this.state.item.prod_name}
                      </h1>
                      <Row>
                          <Col xs={12} sm={6}>
                          {this.state.item.attrs.find(i => i.display_name === "GTIN") ?
                              <p className="mt-3" style={{color:'#666', fontSize:'0.9rem'}}>GTIN: {this.state.item.attrs.find(i => i.display_name === "GTIN").value}</p> : null
                          }
                          </Col>
                          <Col xs={12} sm={6} className="justify-content-end text-right">
                              <DetailsExport item={this.state.item}/>
                          </Col>
                      </Row>
                      {this.state.description && <p>{this.state.description}</p>}
                      <div className="mt-5">
                          <Tabs defaultActiveKey="description" id="product-tab-content">
                              <Tab eventKey="description" title="Характеристики">
                                  <Accordion defaultActiveKey="">
                                      {this.state.classifier.length > 0 &&
                                      <Card>
                                          <Card.Header>
                                              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <FontAwesomeIcon  icon={faAngleRight}/> Внешние классификаторы и происхождение товара
                                              </Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey="1">
                                              <Card.Body>
                                                  {
                                                      this.state.classifier.map((i, index) =>
                                                          <div key={index}>
                                                              <label key={"l" + index} className="labelRow">
                                                                  {i.display_name}
                                                              </label>
                                                              <div key={index} className="dataRow">
                                                                  {
                                                                      i.value_ext && i.value_ext.name_short ?
                                                                         i.value_ext.name_short
                                                                          :
                                                                          i.value
                                                                  }
                                                                  {
                                                                      i.value_ext && i.value_ext.address ?
                                                                      ', ' + i.value_ext.address : ''
                                                                  }
                                                              </div>
                                                          </div>)}
                                              </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                      }
                                      {this.state.logistic.length > 0 &&
                                      <Card>
                                          <Card.Header>
                                              <Accordion.Toggle as={Button} variant="link" eventKey="2"><FontAwesomeIcon  icon={faAngleRight}/>  Логистика. Условия и сроки хранения и транспортировки</Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey="2">
                                              <Card.Body>
                                                  {this.state.logistic.map((i, index) => <div key={index}><label key={"l" + index} className="labelRow">{i.display_name}</label><div key={index} className="dataRow">{i.value}</div></div>)}
                                              </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                      }
                                      {this.state.profile.length > 0 &&
                                      <Card>
                                          <Card.Header>
                                              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                  <FontAwesomeIcon  icon={faAngleRight}/> Профиль потребителя
                                              </Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey="3">
                                              <Card.Body>
                                                  {this.state.profile.map((i,index) => <div key={index}><label key={"l" + index} className="labelRow">{i.display_name}</label><div key={index} className="dataRow">{i.value}</div></div>)}
                                              </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                      }
                                      {this.state.userAttr.length > 0 &&
                                          <Card>
                                              <Card.Header>
                                                  <Accordion.Toggle as={Button} variant="link" eventKey="4" >
                                                      <FontAwesomeIcon  icon={faAngleRight}/> Потребительские характеристики
                                                  </Accordion.Toggle>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey="4">
                                                  <Card.Body>
                                                    {this.state.userAttr.map((i,index) => <div key={index}><label key={"l" + index} className="labelRow" id={i.order}>{i.display_name}</label><div key={index} className="dataRow">{i.value}</div></div>)}
                                                  </Card.Body>
                                              </Accordion.Collapse>
                                          </Card>
                                      }
                                      {this.state.merchandising.length > 0 &&
                                          <Card>
                                              <Card.Header>
                                                  <Accordion.Toggle as={Button} variant="link" eventKey="5"><FontAwesomeIcon  icon={faAngleRight}/> Мерчендайзинг</Accordion.Toggle>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey="5">
                                                    <Card.Body>
                                                          {this.state.merchandising.map((i, index) => <div key={index}><label key={"l" + index} className="labelRow">{i.display_name}</label><div key={index} className="dataRow">{i.value}</div></div>)}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                          </Card>
                                      }

                                      {this.state.marketing.length > 0 &&
                                      <Card>
                                          <Card.Header>
                                              <Accordion.Toggle as={Button} variant="link" eventKey="6"><FontAwesomeIcon  icon={faAngleRight}/> Маркетинг</Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey="6">
                                              <Card.Body>
                                                  {this.state.marketing.map((i, index) => <div key={index}>
                                                      <label key={"l" + index} className="labelRow" id={i.order}>
                                                          {i.display_name}
                                                      </label>
                                                      <div key={index} className="dataRow">
                                                          {i.value_ext && i.value_ext.name_short? i.value_ext.name_short : i.value}
                                                      </div>
                                                  </div>)}
                                              </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                      }
                                      {this.state.channels.length > 0 &&
                                      <Card>
                                          <Card.Header>
                                              <Accordion.Toggle as={Button} variant="link" eventKey="7"><FontAwesomeIcon  icon={faAngleRight}/> Атрибуты компании ООО "МАРС"</Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey="7">
                                              <Card.Body>
                                                  {this.state.channels.map((i,index) => <div key={index}>
                                                      <label className="labelRow" key={"l" + index}>
                                                          {i.display_name}
                                                      </label>
                                                      <div key={index} className="dataRow" id={"attr-" + i.id}>
                                                          {i.value_ext && i.value_ext.name_short? i.value_ext.name_short : i.value}
                                                      </div>
                                                  </div>)}
                                              </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                      }
                                      {this.state.otherAttrs.length > 0 &&
                                      <Card>
                                          <Card.Header>
                                              <Accordion.Toggle as={Button} variant="link" eventKey="9"><FontAwesomeIcon  icon={faAngleRight}/> Прочие</Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey="9">
                                              <Card.Body>
                                                  {this.state.otherAttrs.map((i, index) => <div key={index}>
                                                      <label className="labelRow" key={"l" + index}>
                                                          {i.display_name}
                                                      </label>
                                                      <div className="dataRow" key={index}>
                                                          {i.value_ext && i.value_ext.name_short? i.value_ext.name_short : i.value}
                                                      </div>
                                                  </div>)}
                                              </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                      }

                                  </Accordion>
                              </Tab>
                              <Tab eventKey="size" title="Упаковка">
                                  <div>
                                      <table className="packages">
                                          <thead>
                                          <tr>
                                              <th>Тип</th>
                                              <th>Вес нетто, кг</th>
                                              <th>Вес брутто, кг</th>
                                              <th>Ш x В x Г, см</th>
                                              <th width={120}>Элементов следующего уровня</th>
                                              <th width={120}>Количество единиц</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.sizes.map(i =>
                                              <tr key={i.id}>
                                                  <td>
                                                      {i.type === 'Единичная' && <FontAwesomeIcon  icon={faBox}/>}
                                                      {i.type === 'Слой' && <FontAwesomeIcon  icon={faLayerGroup}/>}
                                                      {i.type === 'Паллета' && <FontAwesomeIcon  icon={faPallet}/>}
                                                      {i.type === 'Групповая' && <FontAwesomeIcon  icon={faBoxes}/>}
                                                      {i.type === 'Транспортная' && <FontAwesomeIcon  icon={faTruckLoading}/>}
                                                      {i.type}
                                                  </td>
                                                  <td>{i.attrs.find(t => t.display_name === "Вес нетто") && i.attrs.find(t => t.display_name === "Вес нетто").value}</td>
                                                  <td>{i.attrs.find(t => t.display_name === "Вес брутто") && i.attrs.find(t => t.display_name === "Вес брутто").value}</td>
                                                  <td>{i.attrs.find(t => t.display_name === "Ширина") && i.attrs.find(t => t.display_name === "Ширина").value} x {i.attrs.find(t => t.display_name === "Высота") && i.attrs.find(t => t.display_name === "Высота").value} x {i.attrs.find(t => t.display_name === "Глубина") && i.attrs.find(t => t.display_name === "Глубина").value}</td>
                                                  <td className="text-center">
                                                      {i.attrs.find(t => t.id === '53071569') && i.attrs.find(t => t.id === '53071569').value}
                                                  </td>
                                                  <td className="text-center">
                                                      {i.items_inside}
                                                  </td>
                                              </tr>

                                          )}
                                          </tbody>
                                      </table>
                                  </div>
                              </Tab>
                              <Tab eventKey="documents" title="Документы">
                                  <div>
                                      <table style={{fontSize:14, margin:"20px 0"}}>
                                          <tbody>
                                          {this.state.documents.map(i =>
                                              <tr key={i.id}>
                                                  <td >
                                                      <a target="_blank" rel="noreferrer" style={{marginTop:20, display:"inline-block", color: "rgb(1 24 160)"}}href={i.url}>
                                                          {i.doc_type + " " + i.display_name}
                                                      </a>
                                                      {
                                                            i.doc_valid_from && i.doc_valid_to &&
                                                            <p>Срок действия с {dates[new Date(i.doc_valid_from).getDate()]} {month[new Date(i.doc_valid_from).getMonth()]} {new Date(i.doc_valid_from).getFullYear() + " "}
                                                                по {dates[new Date(i.doc_valid_to).getDate()]} {month[new Date(i.doc_valid_to).getMonth()] + " "}
                                                                {new Date(i.doc_valid_to).getFullYear()}</p>
                                                      }
                                                  </td>
                                              </tr>)}
                                          </tbody>
                                      </table>
                                  </div>
                              </Tab>
                          </Tabs>
                      </div>
              </Col>
              <Col xs={{ span: 12, order: 'first' }} lg={{ span: 5, order: 'last' }}>
                  <div className="product-image-container">
                      <div className="product-image-large">
                          <img
                              src={this.state.mainImage ? this.state.mainImage.url + "?wmax=1200&hmax=1200" : nophoto}
                              alt=""
                              width={"100%"}
                              style={{
                                  borderRadius: "5px",
                                  objectFit: "contain",
                                  maxHeight: "100%",
                              }}
                          />
                          {this.state.mainImage &&
                              <Button id="dropdown-image-download" onClick={() => this.downloadImage(this.state.mainImage)}>
                                  <FontAwesomeIcon icon={faDownload}/>
                              </Button>
                          }
                      </div>
                      <Carousel centerMode={true} showDots={false} infinite={true} responsive={responsive}>
                          {this.state.images.map(i =>
                              <div key={i.id}
                                  className={i.photo_is_main === 1 ? "active preview" : "preview"}
                                  onClick={() => {
                                      this.setState({
                                          mainImage: {
                                              url: i.url,
                                              display_name: i.display_name
                                          }
                                      })
                                      let images = this.state.images
                                      images.forEach(t => t.photo_is_main = 0)
                                      images.find(t => t.url === i.url).photo_is_main = 1
                                  }}
                              >
                                  <img
                                      width={40}
                                      height={40}
                                      src={i.url + "?wmax=200&hmax=200"}
                                      style={{
                                          objectFit: "contain"
                                      }}
                                      alt={i.display_name}
                                  />
                              </div>)}
                      </Carousel>
                  </div>
              </Col>

          </Row>
      </Container>
    );
  }
}

let Details = connect()(ConnectedDetails);
export default Details;
