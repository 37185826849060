import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {brands} from "../../Data"
import {Col, Button, Row} from "react-bootstrap"
import {faTh, faList, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class ProductsHeader extends Component {
  state = {
    openPriceDialog: false
  };

  render() {
    let { parsedQueryStr, totalItemsCount, view } = this.props;


    let keyword = parsedQueryStr.term;
    let category = brands.find(b => b.id === parsedQueryStr.category);
    if (category) {
        category = category.value_ru
    }
    let subtitle = (
      <span>
          {keyword && (
            <span className="keyword">
            <FontAwesomeIcon  icon={faSearch}/> {keyword}
          </span>
          )}
          { category && !keyword?
            ", всего товаров: "
            : !category && !keyword?
            "Всего товаров: " : "Найдено товаров: "
          }
          {totalItemsCount ? totalItemsCount : "0"}
      </span>
    );

    return (
        <Row style={{ padding: "15px 0", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
            <Col xs={12} md={6} className="mt-2">
            {category ? category : ""}{subtitle}
            </Col>
            <Col xs={12} md={6} className="text-right">
                <Button className={view === 1? "active view-selector": "view-selector"} onClick={() => {this.props.toggleView(1)}}>
                    <FontAwesomeIcon  icon={faTh}/>
                </Button>
                <Button  className={view === 2? "active view-selector": "view-selector"} onClick={() => {this.props.toggleView(2)}}>
                    <FontAwesomeIcon  icon={faList}/>
                </Button>
            </Col>
        </Row>
    );
  }
}

export default withRouter(ProductsHeader);
