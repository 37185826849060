// Our product database.
const sampleProducts = [
  {
    id: 1,
    name: "Кондиционер Dove Advanced Hair Series для волос прогрессивное восстановление 250мл",
    category: "Clothing and Shoes",
    price: 55,
    description:
      "The Nike Air Presto Women's Shoe delivers the same unrivaled fit and comfort that marked the 2000 debut of the original.",
    popular: true,
    imageUrls: [
      "https://mdm.esphere.ru/ords/mdm/api/tmpblob?uniq=3007186580&rnd=L4P0OBBI3BLRF4XPP3FJ",
      "https://media.hypedc.com/media/catalog/product/cache/1/image/750x/9df78eab33525d08d6e5fb8d27136e95/_/o/_o8a4329_2.jpg"
    ]
  }
];

const brands = [
  {
    "rn": 1,
    "id": "3711750605",
    "comments": "MARS Wrigley",
    "value_ru": "Five",
    "value_en": "Five"
  },
  {
    "rn": 2,
    "id": "3711750688",
    "comments": "MARS Wrigley",
    "value_ru": "Be-Kind",
    "value_en": "Be-Kind",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/BE%20KIND_3.jpg"
  },
  {
    "rn": 3,
    "id": "3711742067",
    "comments": "MARS Wrigley",
    "value_ru": "Bounty",
    "value_en": "Bounty"
  },
  {
    "rn": 4,
    "id": "3713138521",
    "comments": "MARS PetFood",
    "value_ru": "CESAR",
    "value_en": "CESAR",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Cesar%20Logo%20sRGB.png?itok=8HmdTKcJ"
  },
  {
    "rn": 5,
    "id": "3713138640",
    "comments": "MARS PetFood",
    "value_ru": "CHAPPI",
    "value_en": "CHAPPI",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Chappi.jpg?itok=qUl5TSM1"
  },
  {
    "rn": 6,
    "id": "3713138402",
    "comments": "MARS PetFood",
    "value_ru": "Catsan",
    "value_en": "Catsan",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Catsan.png?itok=8XGjjIvz"
  },
  {
    "rn": 7,
    "id": "3711742089",
    "comments": "MARS Wrigley",
    "value_ru": "Celebrations",
    "value_en": "Celebrations"
  },
  {
    "rn": 8,
    "id": "3713134040",
    "comments": "MARS Food",
    "value_ru": "DOLMIO",
    "value_en": "DOLMIO",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Mars%20Food%20product%20logos%20web%20Dolmio%20Large.png?itok=-FgOZJ7s"
  },
  {
    "rn": 9,
    "id": "3711750771",
    "comments": "MARS Wrigley",
    "value_ru": "Doublemint",
    "value_en": "Doublemint"
  },
  {
    "rn": 10,
    "id": "3711742079",
    "comments": "MARS Wrigley",
    "value_ru": "Dove",
    "value_en": "Dove",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Large-Dove%20Logo%20sRGB.jpg"
  },
  {
    "rn": 11,
    "id": "3713138759",
    "comments": "MARS PetFood",
    "value_ru": "DREAMIES",
    "value_en": "DREAMIES",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Mars%20Petcare%20product%20logos%20web%20Dreamies%20Large.png?itok=iXyZFMqY"
  },
  {
    "rn": 12,
    "id": "3711750854",
    "comments": "MARS Wrigley",
    "value_ru": "Eclipse",
    "value_en": "Eclipse"
  },
  {
    "rn": 13,
    "id": "3713138878",
    "comments": "MARS PetFood",
    "value_ru": "ELIOTT",
    "value_en": "ELIOTT"
  },
  {
    "rn": 14,
    "id": "3711750937",
    "comments": "MARS Wrigley",
    "value_ru": "Juicy Fruit",
    "value_en": "Juicy Fruit",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Mars%20Wrigley%20product%20logos%20web%20Juicy%20Fruit%20Large.png"
  },
  {
    "rn": 15,
    "id": "3713138997",
    "comments": "MARS PetFood",
    "value_ru": "KITEKAT",
    "value_en": "KITEKAT",
    "image": "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Kitekat.jpg?itok=cg9K-2PD"
  },
  {
    "rn": 16,
    "id": "3711742075",
    "comments": "MARS Wrigley",
    "value_ru": "M&M's",
    "value_en": "M&M's",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Large-M_M_s%20Logo%20sRGB.jpg"
  },
  {
    "rn": 18,
    "id": "3711742077",
    "comments": "MARS Wrigley",
    "value_ru": "Maltesers",
    "value_en": "Maltesers",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Mars%20Wrigley%20product%20logos%20web%20Maltesers%20Large_0.png"
  },
  {
    "rn": 19,
    "id": "3711742069",
    "comments": "MARS Wrigley",
    "value_ru": "Mars",
    "value_en": "Mars",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Large-Mars%20Logo%20sRGB.jpg"
  },
  {
    "rn": 20,
    "id": "3711742073",
    "comments": "MARS Wrigley",
    "value_ru": "Milky Way",
    "value_en": "Milky Way",
    "image": "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Milky%20Way.jpg"
  },
  {
    "rn": 21,
    "id": "3713134159",
    "comments": "MARS Food",
    "value_ru": "MasterFoods",
    "value_en": "MasterFoods"
  },
  {
    "rn": 23,
    "id": "3711742087",
    "comments": "MARS Wrigley",
    "value_ru": "Mixed Minis",
    "value_en": "Mixed Minis"
  },
  {
    "rn": 24,
    "id": "3713139116",
    "comments": "MARS PetFood",
    "value_ru": "NATURE'S TABLE",
    "value_en": "NATURE'S TABLE",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Natures%20Table_0.jpg?itok=VJUSwBg1"
  },
  {
    "rn": 25,
    "id": "3711751020",
    "comments": "MARS Wrigley",
    "value_ru": "Orbit",
    "value_en": "Orbit",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Large-Orbit%20Logo%20sRGB.jpg"
  },
  {
    "rn": 26,
    "id": "3713139235",
    "comments": "MARS PetFood",
    "value_ru": "PEDIGREE",
    "value_en": "PEDIGREE",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-04/Logos_Pedigree.png?itok=s71ToJVO"
  },
  {
    "rn": 27,
    "id": "3713139354",
    "comments": "MARS PetFood",
    "value_ru": "PERFECT FIT",
    "value_en": "PERFECT FIT",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Mars%20Petcare%20product%20logos%20web%20Perfect%20Fit%20Large.png?itok=E1tUOSS5"
  },
  {
    "rn": 28,
    "id": "3711751103",
    "comments": "MARS Wrigley",
    "value_ru": "Rondo",
    "value_en": "Rondo"
  },
  {
    "rn": 29,
    "id": "3713139473",
    "comments": "MARS PetFood",
    "value_ru": "SHEBA",
    "value_en": "SHEBA",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Mars%20Petcare%20product%20logos%20web%20Sheba%20Large.png?itok=aRLD5ZPi"
  },
  {
    "rn": 30,
    "id": "3711751186",
    "comments": "MARS Wrigley",
    "value_ru": "Skittles",
    "value_en": "Skittles",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Large-Skittles%20Logo%20sRGB.jpg"
  },
  {
    "rn": 31,
    "id": "3711742071",
    "comments": "MARS Wrigley",
    "value_ru": "Snickers",
    "value_en": "Snickers",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Large-Snickers%20Logo%20sRGB.jpg"
  },
  {
    "rn": 32,
    "id": "3711751269",
    "comments": "MARS Wrigley",
    "value_ru": "Spearmint",
    "value_en": "Spearmint"
  },
  {
    "rn": 34,
    "id": "3711742065",
    "comments": "MARS Wrigley",
    "value_ru": "Twix",
    "value_en": "Twix",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/Mars%20Wrigley%20product%20logos%20web%20Twix%20Large.png"
  },
  {
    "rn": 35,
    "id": "3713134278",
    "comments": "MARS Food",
    "value_ru": "Uncle Ben’s",
    "value_en": "Uncle Ben’s",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Uncle%20Bens%20Logo%20sRGB.png?itok=1GNi2eIU"
  },
  {
    "rn": 36,
    "id": "3713139592",
    "comments": "MARS PetFood",
    "value_ru": "WHISKAS",
    "value_en": "WHISKAS",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/brands_grid/public/2019-10/Whiskas%20sRGB.png?itok=TwHEaEkt"
  },
  {
    "rn": 37,
    "id": "3711751352",
    "comments": "MARS Wrigley",
    "value_ru": "Коркунов",
    "value_en": "Коркунов",
    image: "https://rus.mars.com/sites/g/files/jydpyr331/files/2019-10/A.Korkunov.jpg"
  },
  {
    "rn": 38,
    "id": "3852962792",
    "comments": "MARS PetFood",
    "value_ru": "CRAVE",
    "value_en": "CRAVE"
  },
  {
    "rn": 39,
    "id": "3857287834",
    "comments": "MARS PetFood",
    "value_ru": "NUTRO",
    "value_en": "NUTRO"
  },
  {
    "rn": 40,
    "id": "3802460853",
    "comments": "MARS Food",
    "value_ru": "Ben's Original",
    "value_en": "Ben's Original",
  },
]


const dataForTheMenu = [
  {
    name: "Petcare",
    desc: "Mars Petcare объединяет более 85 тысяч сотрудников, которые заботятся о домашних животных во всем мире. Мы создаем качественное питание, предоставляем ветеринарную поддержку и другие сервисы. Вместе мы делаем мир для домашних животных лучше.",
    id: 1,
    img: "https://rus.mars.com/sites/g/files/jydpyr331/files/styles/image_copy/public/2019-04/Petcare%20article%20image%20x2.png?itok=gF9Ha4il",
    children: brands.filter(e => e.comments === "MARS PetFood").map((x, i) => {
        return {
          name: x.value_ru,
          nameEn: x.value_en,
          id: i,
          url: "/products?category=" + x.id,
          icon: x.icon,
          image: x.image,
          cross_id:x.id
        };
    })
  },
  {
    name: "Wrigley",
    id: 2,
    img:"https://rus.mars.com/sites/g/files/jydpyr331/files/styles/image_copy/public/2019-06/Made%20By%20Mars%20-%20Confectionery_2_1.jpg?itok=5nMMwKmK",
    desc: "Лучшие моменты дарят миру улыбки. Уже больше 100 лет мы создаем лучшие моменты благодаря нашим лакомствам и перекусам: M&M’S®, SNICKERS®, ORBIT® и TWIX®. Почему мы это делаем каждый день? Мы хотим делать наши продукты еще лучше, делиться ими с окружающими и дарить миру еще больше улыбок!",
    children: brands.filter(e => e.comments === "MARS Wrigley").map((x, i) => {
      return {
        name: x.value_ru,
        nameEn: x.value_en,
        id: i,
        url: "/products?category=" + x.id,
        icon: x.icon,
        image: x.image,
        cross_id:x.id
      };
    })
  }
  ,
  {
    name: "Food",
    id: 3,
    img:"https://rus.mars.com/sites/g/files/jydpyr331/files/styles/image_copy/public/2019-06/Made%20By%20Mars%20-%20Food_0_0.jpg?itok=VjoExIhC",
    desc: "Еда — это не просто калории и питательные вещества — это нечто большее. Наша цель — улучшать питание сегодня, чтобы создавать лучший мир завтра. Наши марки UNCLE BEN'S®, DOLMIO® и SEEDS OF CHANGE™ могут и должны положительно влиять на людей и планету. Сегодня мы работаем над тем, чтобы к 2021 году миллиард здоровых блюд стоял на обеденных по всему миру.",
    children: brands.filter(e => e.comments === "MARS Food").map((x, i) => {
      return {
        name: x.value_ru,
        nameEn: x.value_en,
        id: i,
        url: "/products?category=" + x.id,
        icon: x.icon,
        image: x.image,
        cross_id:x.id
      };
    })
  }
];

export { sampleProducts, dataForTheMenu, brands };
